'use client'
import { storyblokEditable } from '@storyblok/react'
import { useMemo } from 'react'
import { Badge } from 'ui/src/components/Badge/Badge'
import { sprinkles } from 'ui/src/theme/sprinkles.css'
import * as GridLayout from '@/components/GridLayout/GridLayout'
import { Perils } from '@/components/Perils/Perils'
import {
  useProductData,
  useSelectedProductVariant,
} from '@/components/ProductData/ProductDataProvider'
import { type SbBaseBlockProps } from '@/services/storyblok/storyblok'

type PerilsBlockProps = SbBaseBlockProps<{
  heading?: string
  hideDisabledPerils?: boolean
}>

export const PerilsBlock = ({ blok, nested }: PerilsBlockProps) => {
  const { variants } = useProductData()
  const selectedProductVariant = useSelectedProductVariant()

  const allPerils = useMemo(() => {
    const perilsMap = new Map(
      variants.flatMap((item) => item.perils.map((peril) => [peril.title, peril])),
    )
    return Array.from(perilsMap.values())
  }, [variants])

  const items = useMemo(() => {
    const productVariant = selectedProductVariant ?? variants.at(0)
    if (!productVariant) return []
    return productVariant.perils.map((item) => ({ id: item.title, ...item }))
  }, [variants, selectedProductVariant])

  const missingItems = useMemo(() => {
    const addedPerils = new Set<string>(items.map((item) => item.id))
    return allPerils.filter((item) => !addedPerils.has(item.title))
  }, [allPerils, items])

  return (
    <GridLayout.Root {...storyblokEditable(blok)} nested={nested}>
      <GridLayout.Content width="1">
        {blok.heading && (
          <Badge className={sprinkles({ marginBottom: 'md' })}>{blok.heading}</Badge>
        )}
        <Perils items={items} missingItems={blok.hideDisabledPerils ? [] : missingItems} />
      </GridLayout.Content>
    </GridLayout.Root>
  )
}
