'use client'

import isPropValid from '@emotion/is-prop-valid'
import type { StyledOptions } from '@emotion/styled'
import styled from '@emotion/styled'
import clsx from 'clsx'
import { type ComponentProps } from 'react'
import { root, rootPadded, contentStatic } from './GridLayout.css'
import type { ContentAlignment, ContentWidth } from './GridLayout.helper'
import { getGridLayout } from './GridLayout.helper'

export function Root({
  className,
  nested,
  ...others
}: ComponentProps<'div'> & { nested?: boolean }) {
  return <div className={clsx(root, !nested && rootPadded, className)} {...others} />
}

type ContentProps = {
  width: ContentWidth
  align?: ContentAlignment
}

const elementConfig: StyledOptions = {
  shouldForwardProp: (prop: string) => isPropValid(prop) && prop !== 'width',
}

export const StyledContent = styled(
  'div',
  elementConfig,
)<ContentProps>(({ width, align }) => ({
  gridColumn: '1 / span 12',
  ...getGridLayout(width, align ?? 'left'),
}))

export function Content({
  className,
  width,
  align = 'left',
  ...others
}: ComponentProps<'div'> & ContentProps) {
  if (typeof width === 'object') {
    return <StyledContent className={className} width={width} align={align} {...others} />
  }

  return (
    <div className={clsx(contentStatic[width], className)} data-alignment={align} {...others} />
  )
}
